import React, {useCallback} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import {useTenant} from '@store/settings'
import {Wrapper} from '../styles'
import SitesMapRow from '@components/sites-map-row'
import MetricsCards from '../metrics-cards'
import TenantsMapRow from '@components/tenants-map-row'

const TenantDashboard = () => {
  const user = useSelector(state => state.auth.user)
  const [tenant, setTenant] = useTenant(user)
  const navigate = useNavigate()

  const handleTenantClick = useCallback(
    tenant => {
      if (!tenant?.id) {
        console.error('Invalid tenant object:', tenant)
        return
      }
      setTenant(tenant)
    },
    [setTenant] // setTenant is a dependency
  )

  const handleSiteClick = useCallback(
    site => {
      if (!site?.id) {
        console.error('Invalid site object:', site)
        return
      }
      navigate(`/site/${site.id}`)
    },
    [navigate]
  )

  if (!tenant) {
    return <TenantsMapRow handleTenantClick={handleTenantClick} />
  }

  return (
    <Wrapper>
      <MetricsCards query={{t_id: tenant.id}} />
      <SitesMapRow tenant={tenant} handleSiteClick={handleSiteClick} />
    </Wrapper>
  )
}

export default React.memo(TenantDashboard)
