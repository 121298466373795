/* eslint-disable no-fallthrough */

import PageHeader from '@components/page-header'
import {DELTA_THERMAL_GLOBAL_HQ, MINZOOM, toLocations} from 'helpers/locations'
import LocationsMap from '@components/locations-map'
import {Card, Col, Row} from 'antd'
import {useEffect, useState} from 'react'
import Address from '@components/address'
import GridRow from '@components/grid-row'
import {widthLarge, widthSmall} from 'helpers/style'

function format(contactInfo) {
  return [
    contactInfo.name,
    contactInfo.address,
    `${contactInfo.city}, ${contactInfo.state} ${contactInfo.zipcode} ${contactInfo.country}`,
    contactInfo.email
  ]
}
const ContactUs = () => {
  const [locations, setLocations] = useState(null)

  useEffect(() => {
    setLocations(toLocations([DELTA_THERMAL_GLOBAL_HQ]))
  }, [])

  return (
    <Row gutter={16} style={{margin: '15px'}}>
      {/* Tenants Card - takes 1/4 width */}
      <Col xs={24} md={8}>
        <Card className={`w-${widthSmall}`}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Contact Us"
            subTitle=""
            backIcon={false}
          />
          <Address title="" address={format(DELTA_THERMAL_GLOBAL_HQ)} />
        </Card>
      </Col>

      {/* Locations Map Card - takes 3/4 width */}
      <Col xs={24} md={16}>
        <Card className="max-w-full">
          <div style={{height: '77vh', width: '61vw'}}>
            <LocationsMap locations={locations} onLocationClick={location => {}} minZoom={MINZOOM.SITES} />
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default ContactUs
