import {Card, Title} from '@tremor/react'
import {List} from 'antd'

const Address = ({title, address}) => {
  return (
    <>
      <Title>{title}</Title>
      <List bordered dataSource={address} renderItem={item => <List.Item>{item}</List.Item>} />
    </>
  )
}

export default Address
