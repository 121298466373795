import {GoogleMap, Marker} from '@react-google-maps/api'
import {MINZOOM} from 'helpers/locations'
import React, {useState, useEffect} from 'react'

const mapContainerStyle = {
  width: '100%',
  height: '100%'
}

const createCustomMarker = (text, subtext) => {
  // Configuration values
  const fontSize = 14
  const padding = 4
  const pointerHeight = 5

  // Create a temporary canvas to measure text width
  const tempCanvas = document.createElement('canvas')
  const tempCtx = tempCanvas.getContext('2d')
  tempCtx.font = `${fontSize}px Roboto`

  // Measure text width accurately
  const measuredTextWidth = tempCtx.measureText(text).width
  const canvasWidth = measuredTextWidth + padding * 2

  // Define marker height (you can adjust as needed)
  const markerHeight = 20 + pointerHeight

  // Create the actual canvas with calculated dimensions.
  const canvas = document.createElement('canvas')
  // Set the intrinsic dimensions
  canvas.width = canvasWidth
  canvas.height = markerHeight

  // Get the drawing context and reapply the font (setting width resets the context state)
  const ctx = canvas.getContext('2d')
  ctx.font = `${fontSize}px Roboto`
  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'

  // Draw the marker shape
  const radius = 8
  ctx.beginPath()
  ctx.moveTo(radius, 0)
  ctx.lineTo(canvasWidth - radius, 0)
  ctx.quadraticCurveTo(canvasWidth, 0, canvasWidth, radius)
  ctx.lineTo(canvasWidth, markerHeight - pointerHeight - radius)
  ctx.quadraticCurveTo(canvasWidth, markerHeight - pointerHeight, canvasWidth - radius, markerHeight - pointerHeight)
  ctx.lineTo(canvasWidth / 2 + pointerHeight, markerHeight - pointerHeight)
  ctx.lineTo(canvasWidth / 2, markerHeight)
  ctx.lineTo(canvasWidth / 2 - pointerHeight, markerHeight - pointerHeight)
  ctx.lineTo(radius, markerHeight - pointerHeight)
  ctx.quadraticCurveTo(0, markerHeight - pointerHeight, 0, markerHeight - pointerHeight - radius)
  ctx.lineTo(0, radius)
  ctx.quadraticCurveTo(0, 0, radius, 0)
  ctx.closePath()

  // Fill the marker shape
  ctx.fillStyle = 'red'
  ctx.fill()

  // Draw the text centered within the upper part of the marker (excluding pointer)
  const textY = (markerHeight - pointerHeight) / 2
  ctx.fillStyle = 'white'
  ctx.fillText(text, canvasWidth / 2, textY)

  // If subtext is provided, measure and draw it with a smaller font below the main text
  if (subtext) {
    const subFontSize = 10
    ctx.font = `${subFontSize}px Roboto`
    // You can adjust vertical placement as needed; for example, place it below the main text.
    // Here we assume the main text takes up approximately one line (fontSize + some margin).
    const mainTextLineHeight = fontSize + 4
    const subTextY = textY + mainTextLineHeight
    ctx.fillText(subtext, canvasWidth / 2, subTextY)
  }

  return canvas.toDataURL()
}

const findLocationByPosition = (locations, position) => {
  const threshold = 0.000001

  return locations.find(location => {
    const latitude = location.latitude ? parseFloat(location.latitude.toString().trim()) : null
    const longitude = location.longitude ? parseFloat(location.longitude.toString().trim()) : null

    // Ensure latitude and longitude are numbers before comparing
    if (latitude === null || longitude === null) {
      return false
    }

    return Math.abs(latitude - position.lat()) < threshold && Math.abs(longitude - position.lng()) < threshold
  })
}

const LocationsMap = ({locations, onLocationClick = () => {}, minZoom = MINZOOM.TENANTS}) => {
  const [markers, setMarkers] = useState([])
  const [map, setMap] = React.useState(null)
  // const [isMapLoaded, setIsMapLoaded] = useState(false)

  useEffect(() => {
    if (map && locations && locations.length > 0) {
      setMarkers(
        locations.map(location => ({
          position: {
            lat: +location.latitude,
            lng: +location.longitude
          },
          label: location.name,
          draggable: false,
          clickable: !!location.id
        }))
      )

      const bounds = new window.google.maps.LatLngBounds()
      locations.forEach(location => {
        bounds.extend({
          lat: +location.latitude,
          lng: +location.longitude
        })
      })

      // Apply bounds to the map
      map.fitBounds(bounds)

      // Add a small delay to ensure fitBounds is applied before adjusting zoom
      setTimeout(() => {
        // Check the current zoom level and adjust if it’s too close
        const currentZoom = map.getZoom()

        if (currentZoom > minZoom) {
          map.setZoom(minZoom)
        }
      }, 100)
    } else {
      setMarkers([])
    }
  }, [locations, map, minZoom])

  const mapClicked = event => {
    console.log(event.latLng.lat(), event.latLng.lng())
  }

  const onLoad = map => {
    setMap(map)
  }

  console.log('Locations=', JSON.stringify(locations))
  console.log('Markers=' + JSON.stringify(markers))
  console.log('MINZOOM=' + minZoom)

  return (
    <GoogleMap
      id="locations-map"
      mapContainerStyle={mapContainerStyle}
      onLoad={onLoad}
      onClick={mapClicked}
      options={{
        mapTypeId: 'roadmap',
        controlSize: 18,
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: false,
        fullscreenControl: true
      }}
    >
      {markers.map((marker, index) => (
        <Marker
          key={index}
          position={marker.position}
          icon={{
            url: createCustomMarker(marker.label),
            scaledSize: new window.google.maps.Size(marker.label.length * 6 + 20, 20)
          }}
          draggable={false}
          onClick={event => onLocationClick(findLocationByPosition(locations, event.latLng))}
        />
      ))}
    </GoogleMap>
  )
}

export default LocationsMap
