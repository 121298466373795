import {Card, Row, Col} from 'antd'
import LocationsMap from './locations-map'
import {useEffect, useState} from 'react'
import {Params, useFetchGet} from 'helpers/api'
import {useGatewaysSettings} from '@store/settings'
import {paginationDefaults, widthSmall} from 'helpers/style'
import PageHeader from './page-header'
import {DELTA_THERMAL_GLOBAL_HQ, MINZOOM, toLocations} from 'helpers/locations'
import {extractGateways} from 'helpers/results'
import TargetStatusLegend from './target-status-legend'
import TargetStatusTable from './target-status-table'

const GatewaysMapRow = ({site, handleGatewayClick}) => {
  const [gateways, setGateways] = useState()
  const [gatewaysMeta, setGatewaysMeta] = useState()
  const fetchGatewaysview = useFetchGet('gatewaysview')
  const [filter, setFilter, page, setPage, limit, setLimit] = useGatewaysSettings()
  const [locations, setLocations] = useState()
  const [minZoom, setMinZoom] = useState(MINZOOM.GATEWAYS)

  const getGateways = () => {
    const params = Params({s_id: site?.id, orderBy: 'g_name', order: 'ASC', filter, limit, page})
    fetchGatewaysview(params, response => {
      const gateways = extractGateways(response.gatewaysview)
      setGateways(gateways)
      setGatewaysMeta(response.meta)
      console.log('gateways=' + JSON.stringify(gateways))
      onGatewaysChange(gateways)
    })
  }

  useEffect(() => {
    if (site) getGateways()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, filter])

  const onGatewaysChange = gateways => {
    if (gateways && gateways.length > 0) {
      setLocations(toLocations(gateways))
    } else {
      // Show DT Headquarters location; leave off id to make not clickable
      setLocations(toLocations([DELTA_THERMAL_GLOBAL_HQ]))
      setMinZoom(MINZOOM.SITES)
    }
  }

  return (
    <Row gutter={16} style={{margin: '15px'}}>
      {/* Gateways Card - takes 1/4 width */}
      <Col xs={24} md={8}>
        <Card className={`w-${widthSmall}`}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Gateways"
            subTitle=""
            backIcon={false}
          />
          <TargetStatusLegend mode={'horizontal'} />
          <TargetStatusTable
            items={gateways}
            handleItemClick={handleGatewayClick}
            pagination={paginationDefaults(gatewaysMeta?.total, limit, gatewaysMeta?.page, setPage, setLimit)}
          />
        </Card>
      </Col>

      {/* Locations Map Card - takes 3/4 width */}
      <Col xs={24} md={16}>
        <Card className="max-w-full">
          <div style={{height: '50vh', width: '61vw'}}>
            <LocationsMap
              locations={locations}
              onLocationClick={location => handleGatewayClick(location)}
              minZoom={minZoom}
            />
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default GatewaysMapRow
