export const aggregateTemperatures = (temperatures, intervalInMinutes) => {
  const aggregatedData = []
  let currentInterval = null
  let intervalData = []

  temperatures.forEach(entry => {
    const timestamp = new Date(entry.timestamp)
    const intervalStart = new Date(timestamp)
    intervalStart.setMinutes(Math.floor(timestamp.getMinutes() / intervalInMinutes) * intervalInMinutes, 0, 0)

    if (!currentInterval || intervalStart.getTime() !== currentInterval.getTime()) {
      if (intervalData.length > 0) {
        // Calculate average, min, and max for the previous interval
        const avgMean = intervalData.reduce((sum, e) => sum + e.mean, 0) / intervalData.length
        const min = Math.min(...intervalData.map(e => e.min))
        const max = Math.max(...intervalData.map(e => e.max))
        const avgAmbient = intervalData.reduce((sum, e) => sum + e.ext_temp, 0) / intervalData.length
        aggregatedData.push({
          timestamp: currentInterval.toISOString(),
          mean: parseFloat(avgMean.toFixed(2)), // Round to 2 decimal places
          min: parseFloat(min.toFixed(2)), // Round to 2 decimal places
          max: parseFloat(max.toFixed(2)), // Round to 2 decimal places
          ext_temp: parseFloat(avgAmbient.toFixed(2)) // Round to 2 decimal places
        })
      }
      // Start a new interval
      currentInterval = intervalStart
      intervalData = [entry]
    } else {
      intervalData.push(entry)
    }
  })

  // Add the last interval
  if (intervalData.length > 0) {
    const avgMean = intervalData.reduce((sum, e) => sum + e.mean, 0) / intervalData.length
    const min = Math.min(...intervalData.map(e => e.min))
    const max = Math.max(...intervalData.map(e => e.max))
    const avgAmbient = intervalData.reduce((sum, e) => sum + e.ext_temp, 0) / intervalData.length
    aggregatedData.push({
      timestamp: currentInterval.toISOString(),
      mean: parseFloat(avgMean.toFixed(2)), // Round to 2 decimal places
      min: parseFloat(min.toFixed(2)), // Round to 2 decimal places
      max: parseFloat(max.toFixed(2)), // Round to 2 decimal places
      ext_temp: parseFloat(avgAmbient.toFixed(2)) // Round to 2 decimal places
    })
  }

  return aggregatedData
}
