import {Card, Title} from '@tremor/react'
import {Descriptions} from 'antd'
import {useCRUD} from 'helpers/api'
import moment from 'moment'
import {useEffect} from 'react'
import {useState} from 'react'

const CameraDetails = ({id}) => {
  const [camera, setCamera] = useState({})
  const {read} = useCRUD('cameras')

  const getCamera = () => {
    read(id, response => {
      setCamera({...response})
    })
  }

  useEffect(() => {
    if (id) getCamera()
  }, [id])

  return (
    <Card>
      <Title className="m-0 text-lg">Camera Details</Title>
      <Descriptions size="small" bordered column={1} layout="horizontal">
        <Descriptions.Item label="ID">{camera?.id}</Descriptions.Item>
        <Descriptions.Item label="Name">{camera?.name}</Descriptions.Item>
        <Descriptions.Item label="Kind">{camera?.kind}</Descriptions.Item>
        <Descriptions.Item label="Hardware Id">{camera?.hardware_id}</Descriptions.Item>
        <Descriptions.Item label="Part Number">{camera?.part_number}</Descriptions.Item>
        <Descriptions.Item label="Serial Number">{camera?.serial_number}</Descriptions.Item>
        <Descriptions.Item label="Timestamp">{moment(camera?.timestamp).local().toISOString()}</Descriptions.Item>
        <Descriptions.Item label="Created">{moment(camera?.created).local().toISOString()}</Descriptions.Item>
        <Descriptions.Item label="Updated">{moment(camera?.updated).local().toISOString()}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default CameraDetails
