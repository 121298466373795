/* eslint-disable no-fallthrough */
import _ from 'lodash'
import Icon from '@components/icon'
import ListFilter from '@components/list-filter'
import {useAuditSettings, useTenant} from '@store/settings'
import {Button, Card, Table} from 'antd'
import PageHeader from '@components/page-header'
import {Params, useFetchGet} from 'helpers/api'
import {isSysAdmin} from 'helpers/role'
import {pageSizeOptions, paginationDefaults, widthLarge} from 'helpers/style'
import moment from 'moment'
import {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import Viewer from './viewer'
import GridRow from '@components/grid-row'

const CompanyLink = ({tenant, setTenant, id, name, separator = ''}) => {
  if (!tenant && id) {
    return (
      <>
        <Button
          type="link"
          onClick={e => {
            e.stopPropagation()
            setTenant({id, name})
          }}
        >
          {name}
        </Button>
        {separator}
      </>
    )
  } else if (tenant && id && tenant.id !== id) {
    return (
      <>
        {name}
        {separator}
      </>
    )
  } else {
    return ''
  }
}

const User = ({tenant, setTenant, row}) => {
  if (row.u_name || row.uid) {
    return (
      <>
        <CompanyLink
          tenant={tenant}
          setTenant={setTenant}
          id={row.u_tid}
          name={row.u_tname}
          separator="/"
        ></CompanyLink>
        {(row.u_name || row.uid) + '@' + row.ip}
      </>
    )
  } else {
    return ''
  }
}

const Asset = ({tenant, setTenant, row}) => {
  if (row.u_name || row.uid) {
    return (
      <>
        <CompanyLink tenant={tenant} setTenant={setTenant} row={row} separator="/"></CompanyLink>
        {(row.u_name || row.uid) + '@' + row.ip}
      </>
    )
  } else {
    return ''
  }
}

const getColumns = ({tenant, setTenant}) =>
  [
    {
      title: 'Timestamp',
      dataIndex: 'created',
      key: 'created',
      render: (text, row) => {
        return moment(row.created).local().format('yyyy/MM/DD h:mm:ss A')
      }
    },
    {
      title: 'User',
      render: (_, row) => {
        if (row.uid) {
          return (
            <>
              <CompanyLink
                tenant={tenant}
                setTenant={setTenant}
                id={row.u_tid}
                name={row.u_tname}
                separator="/"
              ></CompanyLink>
              {row.u_name || row.uid}
            </>
          )
        } else {
          return 'System'
        }
      }
    },
    {
      title: 'Event',
      dataIndex: 'event_type',
      key: 'event_type'
    },
    {
      title: 'Description',
      dataIndex: 'item_name',
      key: 'item_name',
      ellipsis: true,
      render: (text, row) => {
        switch (row.event_type) {
          case 'login':
          case 'password_change':
            return <>IP: {row.ip}</>
          case 'forgot_password':
            return (
              (row.data.sent ? 'Email sent' : 'Email failure') +
              (row.data.to ? ',To:' + row.data.to : '') +
              (row.data.error ? ',Error:' + row.data.error : '')
            )
            break
          case 'add_item':
          case 'edit_item':
          case 'move_item':
          case 'delete_item':
            switch (row.item_type) {
              case 'tenant':
                if (tenant) {
                  return <>Company:{row.t_name}</>
                } else {
                  return (
                    <>
                      Company:
                      <CompanyLink
                        tenant={tenant}
                        setTenant={setTenant}
                        id={row.tid}
                        name={row.t_name}
                        separator={<Icon name="contains" />}
                      ></CompanyLink>
                    </>
                  )
                }
              case 'site':
                return (
                  <>
                    Site:
                    <CompanyLink
                      tenant={tenant}
                      setTenant={setTenant}
                      id={row.tid}
                      name={row.t_name}
                      separator={<Icon name="contains" />}
                    ></CompanyLink>
                    {row.data.item?.name}
                  </>
                )
              case 'gateway':
                return (
                  <>
                    Gateway:
                    <CompanyLink
                      tenant={tenant}
                      setTenant={setTenant}
                      id={row.tid}
                      name={row.t_name}
                      separator={<Icon name="contains" />}
                    ></CompanyLink>
                    {row.data.item?.sname}
                    <Icon name="contains" />
                    {row.data.item?.name}
                  </>
                )
              case 'camera':
                return (
                  <>
                    Camera:
                    <CompanyLink
                      tenant={tenant}
                      setTenant={setTenant}
                      id={row.tid}
                      name={row.t_name}
                      separator={<Icon name="contains" />}
                    ></CompanyLink>
                    {row.data.item?.sname}
                    <Icon name="contains" />
                    {row.data.item?.gname}
                    <Icon name="contains" />
                    {row.data.item?.name}
                  </>
                )
              case 'target':
                return (
                  <>
                    Target:
                    <CompanyLink
                      tenant={tenant}
                      setTenant={setTenant}
                      id={row.tid}
                      name={row.t_name}
                      separator={<Icon name="contains" />}
                    ></CompanyLink>
                    {row.data.item?.sname}
                    <Icon name="contains" />
                    {row.data.item?.gname}
                    <Icon name="contains" />
                    {row.data.item?.caname}
                    <Icon name="contains" />
                    {row.data.item?.name}
                  </>
                )
              case 'contact':
                return (
                  <>
                    Contact:
                    <CompanyLink
                      tenant={tenant}
                      setTenant={setTenant}
                      id={row.tid}
                      name={row.t_name}
                      separator="/"
                    ></CompanyLink>
                    {row.data.item?.name}
                  </>
                )
              case 'user':
                return (
                  <>
                    User:
                    <CompanyLink
                      tenant={tenant}
                      setTenant={setTenant}
                      id={row.tid}
                      name={row.t_name}
                      separator="/"
                    ></CompanyLink>
                    {row.data.item?.name}
                  </>
                )
              default:
                return _.capitalize(row.item_type) + ':' + row.item_id
            }
            break
          case 'login_failure':
          case 'auth_failure':
          case 'session_terminated':
          case 'api_failure':
          case 'db_migration':
          case 'software_update':
          case 'startup':
          case 'system_error':
            if (row.data.name) {
              return row.data.name
            } else {
              return JSON.stringify(row.data)
                .replace('item:{},', '')
                .replaceAll('{', '')
                .replaceAll('}', '')
                .replaceAll(',', ', ')
                .replaceAll('":"', '-')
                .replaceAll('"', '')
            }
        }
      }
    }
  ].filter(item => !item.hidden)

const Audit = () => {
  const user = useSelector(state => state.auth.user)
  const [tenant, setTenant] = useTenant(user)
  const fetchAuditview = useFetchGet('auditview')
  const [filter, setFilter, page, setPage, limit, setLimit] = useAuditSettings()
  const [data, setData] = useState([])
  const [meta, setMeta] = useState({})
  const [selectedItem, setSelectedItem] = useState()

  useEffect(() => {
    const getAudit = () => {
      const params = Params({filter, limit, page, orderBy: 'created', order: 'DESC'})
      if (isSysAdmin(user) && tenant) params.append('tid', tenant.id)
      fetchAuditview(params, response => {
        setData(response.auditview)
        setMeta(response.meta)
      })
    }

    getAudit()
  }, [filter, page, limit, tenant, user])

  const columns = useMemo(() => {
    return getColumns({tenant, setTenant})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant])

  return (
    <>
      <GridRow layout={[24]}>
        <Card className={`w-${widthLarge}`}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Audit"
            subTitle=""
            backIcon={false}
            extra={[
              <ListFilter
                onFilter={value => {
                  setFilter(value)
                  setPage(1)
                }}
                filter={filter}
                tenant={tenant}
              />
            ]}
          />

          <Table
            columns={columns}
            dataSource={data}
            rowKey={audit => audit.id + '_' + audit.created}
            loading={!data}
            pagination={paginationDefaults(meta?.total, limit, meta?.page, setPage, setLimit)}
            onRow={(row, rowIndex) => {
              return {
                onClick: event => {
                  setSelectedItem(row)
                }
              }
            }}
          />
        </Card>
      </GridRow>
      {typeof selectedItem === 'object' && <Viewer item={selectedItem} onClose={() => setSelectedItem()} />}
    </>
  )
}

export default Audit
