import ActionSelect from '@components/action-select'
import {Alert, Button, Form, Input, Space, message, Typography} from 'antd'
import {useCRUD} from 'helpers/api'
import {useEffect, useState} from 'react'

const {Title} = Typography

export const formFields = {name: 'Name', latitude: 'Latitude', longitude: 'Longitude'}

export const buildTenant = (isNew, action, item) => {
  console.log('buildTenant', isNew, action, JSON.stringify(item))
  if (isNew || action === 'Add') {
    return {
      id: null,
      name: '',
      longitude: null,
      latitude: null
    }
  } else {
    const value = {...item?.tenant}
    console.log('value', value)
    return value
  }
}

const TenantForm = ({tenant, selectedAction, onClose, onSuccess}) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState(tenant)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const {create, read, update} = useCRUD('tenants')

  const handleClose = () => {
    setError(null)
    setIsLoading(false)
    onClose()
  }

  const fetchItem = () => {
    read(tenant.id, response => {
      setFormData({...response})
    })
  }

  useEffect(() => {
    if (tenant?.id) {
      fetchItem()
    } else {
      setFormData(tenant)
    }
  }, [tenant, selectedAction])

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [formData, selectedAction])

  const onFinish = values => {
    if (!tenant?.id) {
      setIsLoading(true)
      create(values, response => {
        setIsLoading(false)
        if (response) {
          onSuccess()
          onClose()
          handleClose()
          message.success('Successfully created tenant!')
        } else {
          setError({status: 400})
        }
      })
    } else {
      setIsLoading(true)
      update(tenant.id, values, response => {
        setIsLoading(false)
        if (response) {
          onSuccess()
          onClose()
          handleClose()
          message.success('Successfully updated tenant!')
        } else {
          setError({status: 400})
        }
      })
    }
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{span: 7}}
      labelWrap={true}
      wrapperCol={{span: 10, offset: 0}}
      initialValues={formData}
      onFinish={onFinish}
      autoComplete="off"
      key={tenant ? tenant.id || -1 : 0}
      form={form}
      onFieldsChange={changedFields => {
        if (changedFields[0].touched) {
          const field = changedFields[0].name[0]
          const value = changedFields[0].value
          const updateFormData = {...formData}
          if ((field === 'latitude' || field === 'longitude') && value.split(',').length > 1) {
            const parts = value.split(',')
            updateFormData.latitude = parts[0]
            updateFormData.longitude = parts[1]
          } else {
            updateFormData[field] = value
          }
          setFormData(updateFormData)
        }
      }}
    >
      {error && (
        <Alert
          message={error?.status === 400 ? 'Company with this name already exists!' : 'Something went wrong!'}
          type="error"
          showIcon
          style={{marginBottom: 24}}
        />
      )}

      <Form.Item className="title" wrapperCol={{span: 4, offset: 10}}>
        <Title level={4}>Company</Title>
      </Form.Item>

      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Name is required!'
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        className="subtitle"
        label="GPS Coordinates (decimal degrees)"
        colon={false}
        labelCol={{span: 10}}
      ></Form.Item>
      <Form.Item
        label="Latitude"
        name="latitude"
        rules={[
          {
            validator: (_, value, callback) => {
              if (value && !form.getFieldValue('longitude')) {
                return Promise.reject(new Error('Longitude is required when Latitude is provided!'))
              }
              return Promise.resolve()
            }
          }
        ]}
      >
        <Input placeholder="0.000000 or 0.000000,0.000000" />
      </Form.Item>

      <Form.Item
        label="Longitude"
        name="longitude"
        rules={[
          {
            validator: (_, value, callback) => {
              if (value && !form.getFieldValue('latitude')) {
                return Promise.reject(new Error('Latitude is required when Longitude is provided!'))
              }
              return Promise.resolve()
            }
          }
        ]}
      >
        <Input placeholder="0.000000" />
      </Form.Item>
      <Form.Item
        className="subtitle"
        label="* Include location to have Company appear on Companies map"
        colon={false}
        labelCol={{span: 21}}
      ></Form.Item>
      <Form.Item className="buttons" wrapperCol={{offset: 8}}>
        <Space>
          <Button data-testid="cancel-form" onClick={onClose}>
            Cancel
          </Button>
          <Button data-testid="submit-form" type="primary" htmlType="submit" loading={isLoading}>
            {!tenant?.id ? 'Add' : 'Save'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default TenantForm
