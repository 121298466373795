import Editor from './editor'
import ListFilter from '@components/list-filter'
import {useAssetsSettings, useTenant} from '@store/settings'
import {Button, Card, Space, Table} from 'antd'
import PageHeader from '@components/page-header'
import {Params, useFetchGet} from 'helpers/api'
import {isSysAdmin} from 'helpers/role'
import {pageSizeOptions, paginationDefaults, widthLarge} from 'helpers/style'
import _ from 'lodash'
import moment from 'moment'
import {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import GridRow from '@components/grid-row'

const getColumns = (tenant, handlers) =>
  [
    {
      title: 'Company',
      render: (_, record) => (
        <Button
          type="link"
          onClick={e => {
            e.stopPropagation()
            handlers.tenant(record.tenant)
          }}
        >
          {record.tenant.name}
        </Button>
      ),
      hidden: !!tenant
    },
    {
      title: 'Site',
      render: (_, record) => record.site.name
    },
    {
      title: 'Gateway',
      render: (_, record) => (
        <Button
          type="link"
          onClick={e => {
            e.stopPropagation()
            handlers.gateway(record.gateway)
          }}
        >
          {record.gateway.name}
        </Button>
      )
    },
    {
      title: 'Camera',
      render: (_, record) => record.camera.name
    },
    {
      title: 'Target',
      render: (_, record) => (
        <Button
          type="link"
          onClick={e => {
            e.stopPropagation()
            handlers.target(record.target)
          }}
        >
          {record.target.name}
        </Button>
      )
    },
    {
      title: 'Last Data Received',
      render: (_, record) => {
        let timestamp = null
        if (record.target && record.target.timestamp) {
          timestamp = record.target.timestamp
        } else if (record.camera && record.camera.timestamp) {
          timestamp = record.camera.timestamp
        } else if (record.gateway && record.gateway.timestamp) {
          timestamp = record.gateway.timestamp
        }

        if (timestamp) {
          // const minutesSinceLastTimestamp = moment().diff(timestamp, 'minutes')
          // const status = minutesSinceLastTimestamp <= record.site.grace_period ? 'good' : 'bad'
          return moment(timestamp).local().format('yyyy/MM/DD h:mm:ss A')
        } else {
          return <></>
        }
      }
    },
    {
      title: (
        <Button
          data-testid="add"
          className="add"
          type="link"
          onClick={() => {
            handlers.item({})
          }}
        >
          Add
        </Button>
      ),
      key: 'action',
      align: 'right',
      width: 200,
      render: (_, record) => {
        return (
          <Space size="middle">
            <Button
              type="link"
              onClick={e => {
                e.stopPropagation()
                handlers.item(record)
              }}
            >
              Edit/Add
            </Button>
          </Space>
        )
      }
    }
  ].filter(item => !item.hidden)

const Assets = () => {
  const navigate = useNavigate()
  const [filter, setFilter, page, setPage, limit, setLimit] = useAssetsSettings()
  const [selectedItem, setSelectedItem] = useState()
  const user = useSelector(state => state.auth.user)
  const [search, setSearch] = useState(null)
  const [searchMeta, setSearchMeta] = useState(null)
  const fetchSearch = useFetchGet('search')
  const [tenant, handleTenantChange] = useTenant(user)

  const getSearch = () => {
    const params = Params({searchTerm: filter, limit, page})
    if (isSysAdmin(user) && tenant) params.append('tid', tenant.id)
    fetchSearch(params, response => {
      setSearch(response.search)
      setSearchMeta(response.meta)
    })
  }

  useEffect(() => {
    getSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, page, limit, user, tenant])

  const data = useMemo(() => {
    return tenant && search && !search[0]?.site?.id ? search.slice(1) : search
  }, [search, tenant])

  const handleGatewayClick = gateway => {
    navigate(`/gateway/${gateway?.id}`)
  }

  const handleTargetClick = target => {
    navigate(`/target/${target?.id}`)
  }

  const columns = useMemo(() => {
    const getAllCols = getColumns(tenant, {
      tenant: handleTenantChange,
      gateway: handleGatewayClick,
      target: handleTargetClick,
      item: setSelectedItem
    })
    if (user?.role !== 'sysadmin') {
      return _.remove(getAllCols, col => {
        return user?.role === 'sysadminRO' || col.key !== 'action'
      })
    }
    return getAllCols
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.role, tenant])

  const rowKey = record => {
    return `${record?.tenant.id},${record?.site?.id},${record?.gateway?.id},${record?.camera?.id},${record?.target?.id}`
  }

  return (
    <>
      <GridRow layout={[24]}>
        <Card className={`w-${widthLarge}`}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Assets"
            subTitle=""
            backIcon={false}
            extra={[
              <ListFilter
                onFilter={value => {
                  setFilter(value)
                  setPage(1)
                }}
                filter={filter}
                tenant={tenant}
              />
            ]}
          />
          <Table
            data-testid="assets-table"
            columns={columns}
            dataSource={data}
            loading={!data}
            pagination={paginationDefaults(searchMeta?.total, limit, searchMeta?.page, setPage, setLimit)}
            rowKey={record => rowKey(record)}
            rowClassName="antd-table-row"
          />
        </Card>
      </GridRow>
      {typeof selectedItem === 'object' && (
        <Editor item={selectedItem} onClose={() => setSelectedItem()} onSuccess={() => getSearch()} />
      )}
    </>
  )
}

export default Assets
