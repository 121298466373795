import {useNavigate, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import PageHeader from '@components/page-header'
import {useFetchGetId} from 'helpers/api'
import GatewaysMapRow from '@components/gateways-map-row'
import {Wrapper} from '../styles'
import MetricsCards from '../metrics-cards'

function getTitle(site) {
  if (!site) return ''
  return (
    <>
      <span className="text-gray-500">Site:</span> {site.name}
    </>
  )
}

const SiteDashboard = () => {
  const {sid} = useParams()
  const navigate = useNavigate()
  const [site, setSite] = useState()
  const fetchSite = useFetchGetId('sites')

  const handleGatewayClick = gateway => {
    if (gateway?.id) {
      navigate(`/gateway/${gateway?.id}`)
    }
  }

  const getSite = async () => {
    await fetchSite(sid, response => {
      setSite(response)
    })
  }

  useEffect(() => {
    if (sid) getSite()
  }, [sid])

  if (!site) return null

  return (
    <Wrapper>
      <div style={{padding: '0 1rem'}}>
        <PageHeader ghost={true} onBack={() => window.history.back()} title={getTitle(site)} subTitle="" extra={[]} />
      </div>
      <MetricsCards query={{s_id: site?.id}} />
      <GatewaysMapRow site={site} handleGatewayClick={handleGatewayClick} />
    </Wrapper>
  )
}

export default SiteDashboard
