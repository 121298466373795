import {CloseCircleFilled} from '@ant-design/icons'
import AntSearch from 'antd/lib/input/Search'
import styled from 'styled-components'

export const Filter = styled(AntSearch)`
  span.ant-input-wrapper.ant-input-group {
    border-color: lightgray;
    border-style: solid;
    border-width: 0px;
  }
`
export const CancelButton = styled(CloseCircleFilled)`
  color: gray;
`
